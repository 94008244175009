import { GroupuiGrid, GroupuiGridCol, GroupuiHeadline, GroupuiInput, GroupuiTextarea, GroupuiSearchField, GroupuiButton, GroupuiCheckbox, GroupuiLoadingSpinner } from '@group-ui/group-ui-react';
import { multiStepContext } from '../StepContext';
import { useContext, useState } from 'react';
import { sendMessage, } from './sendMessage';
import { useCookies } from 'react-cookie'
import { sendAuthorizationRequest, sendTokenRequest, sendRefreshTokenRequest } from './tokenService';

export default function ReviewMessage({ Recipients, RecipientsChecked, Severity, Subject, Message, setLogResponse }) {
    const { setStep } = useContext(multiStepContext)
    const [cookies, setCookie] = useCookies(false)
    const [isSent, setIsSent] = useState(true)
    const [selectedCheckboxes] = useState([]);
    const triggerSendMessage = async () => {
        const combined_subject = `[${Severity.charAt(0).toUpperCase() + Severity.slice(1)} Priority] ${Subject}`
        setIsSent(false)

        let response = await sendMessage(RecipientsChecked, combined_subject, Message, cookies.ACCESS_TOKEN)
        if (response.response?.status === 401) {
            //get new access token with refresh token
            const newAccessToken = await sendRefreshTokenRequest(cookies.REFRESH_TOKEN).then(response => {
                let currentDate = new Date()
                let expiresAccessToken = new Date(currentDate.getTime() + response[0].expires_in * 1000)
                let expiresRefreshToken = new Date(currentDate.getTime() + response[0].refresh_expires_in * 1000)

                setCookie('ACCESS_TOKEN', response[0].access_token, { path: '/', expires: expiresAccessToken })
                setCookie('REFRESH_TOKEN', response[0].refresh_token, { path: '/', expires: expiresRefreshToken })
                return response[0].access_token
            })
            response = await sendMessage(RecipientsChecked, combined_subject, Message, newAccessToken)
            setIsSent(true)
            setLogResponse(response)
            setStep(3)

        } else {
            setIsSent(true)
            setLogResponse(response)
            setStep(3)
        }
    }
    const renderCheckboxes = () => {
        const checkboxes = [];
        const groupedData = {};
        Recipients.forEach(item => {
            const recipientName = item.recipient_name.S;
            const roleName = item.role_name.S;

            if (!groupedData[recipientName]) {
                groupedData[recipientName] = [];
            }
            groupedData[recipientName].push({ roleName, isChecked: selectedCheckboxes.includes(roleName) });
        });

        for (const recipientName in groupedData) {
            const children = groupedData[recipientName].map(child => (
                <div className='checkbox-sub' key={child.roleName}>
                    <GroupuiCheckbox
                        disabled
                        checked={!!RecipientsChecked[recipientName]?.[child.roleName]}
                        id={recipientName}
                    >
                        {child.roleName}
                    </GroupuiCheckbox>
                </div>
            ));

            checkboxes.push(
                <div className="checkbox" key={recipientName}>
                    <GroupuiCheckbox
                        disabled
                        checked={!!RecipientsChecked[recipientName]}
                        id={recipientName}
                    >
                        <b>{recipientName}</b>
                    </GroupuiCheckbox>
                    {children}
                </div>
            );
        }

        return checkboxes;
    };

    return <div>
        <GroupuiGrid gutter="16px" margin-type="custom" margin="0px">
            <GroupuiLoadingSpinner displayed={!isSent}></GroupuiLoadingSpinner>
            <GroupuiHeadline heading="h1">
                Review your message
            </GroupuiHeadline>
            {/* <GroupuiText id="description">
              The first inspection after delivery is due for most VW vehicles
              after three years or 60,000 kilometers, then every 2 years or
              60,000 km.
            </GroupuiText> */}
            <GroupuiHeadline heading="h5" className="sub-headings">
                Message information
            </GroupuiHeadline>
            <GroupuiGridCol m={2} l={6}>
                <GroupuiSearchField disabled placeholder="Search for a template" autofocus={true} directlyOpen={true}>
                    <span slot="label">Template</span>
                    <span slot="description">Select a template</span>
                </GroupuiSearchField>
            </GroupuiGridCol>
            <GroupuiHeadline heading="h5" className="sub-headings">
                Severity
            </GroupuiHeadline>
            <div className="buttongroup-radiobutton container-border flex-center">
                <groupui-button-group>
                    <groupui-button-group-button checked={Severity === 'low' && true}>Low</groupui-button-group-button>
                    <groupui-button-group-button checked={Severity === 'high' && true}>High</groupui-button-group-button>
                    <groupui-button-group-button checked={Severity === 'critical' && true}>Critical</groupui-button-group-button>
                </groupui-button-group>
            </div>

            <GroupuiHeadline heading="h5" className="sub-headings">
                Subject
            </GroupuiHeadline>
            <GroupuiInput disabled value={Severity ? `[${Severity.charAt(0).toUpperCase() + Severity.slice(1)} Priority] ${Subject}` : Subject} placeholder="Your subject">

            </GroupuiInput>
            <GroupuiHeadline heading="h5" className="sub-headings">
                Message
            </GroupuiHeadline>
            <GroupuiTextarea
                disabled
                placeholder="Your message"
                rows={14}
                className="textarea"
                value={Message}
                autoExpand={false}
            />
            <GroupuiHeadline heading="h5" className="sub-headings">
                Recipients
            </GroupuiHeadline>

            {renderCheckboxes()}
            <div className="button-col">
                <GroupuiButton variant="secondary" onClick={() => setStep(1)}>Edit again</GroupuiButton>
                <GroupuiButton onClick={() => triggerSendMessage()}>Send</GroupuiButton>
            </div>
        </GroupuiGrid>

    </div>
};