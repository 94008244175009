import axios from "axios";
import { CONFIG } from "../config";


export const sendMessage = async (recipients_selection, subject, message, access_token) => {
    try {
        const response_send_message = await axios.post(
            `${CONFIG.APPLICATION_URL}api/sendmessage`,
            {

                "recipients_selection": recipients_selection,
                "subject": subject,
                "message": message
            },
            {
                headers: {
                    'Token': access_token,
                }
            });
        return response_send_message.data
    }
    catch (error) {
        console.log("ERROR WHILE SENDING MESSAGE")
        return error
    }
}

