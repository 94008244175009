import { GroupuiGrid, GroupuiHeadline, GroupuiTextarea, GroupuiTable, GroupuiTableCell, GroupuiChip, GroupuiTooltip } from '@group-ui/group-ui-react';
import { useState } from 'react';
import '../App.css';
// import { multiStepContext } from '../StepContext';
// import { useContext } from 'react';
export default function CheckLogOutput(LogResponse) {
    //const { setStep } = useContext(multiStepContext)
    const email_status = LogResponse['LogResponse']['email_status']
    const rocketchat_status = LogResponse['LogResponse']['rocketchat_status']

    return <div>
        <GroupuiGrid gutter="16px" margin-type="custom" margin="0px">
            <GroupuiHeadline heading="h1">
                Messages Status
            </GroupuiHeadline>
            <h3>Emails</h3>
            <GroupuiTable columns="4" sticky={false} header={true} widths="80px auto auto auto" indicator="default">
                <GroupuiTableCell>Status</GroupuiTableCell>
                <GroupuiTableCell>Project Name</GroupuiTableCell>
                <GroupuiTableCell>Role Name</GroupuiTableCell>
                <GroupuiTableCell>Email</GroupuiTableCell>
                {email_status.map((item) => (
                    <>
                        {item.success ? (
                            <GroupuiTableCell><GroupuiChip color="success">Sucess</GroupuiChip></GroupuiTableCell >
                        ) : (
                            <GroupuiTableCell><GroupuiTooltip><GroupuiChip color="error">Error</GroupuiChip><div style={{ whiteSpace: "pre-wrap" }} slot="tooltip">{item.error_message}</div></GroupuiTooltip></GroupuiTableCell>

                        )}
                        <GroupuiTableCell>{item.recipient_name}</GroupuiTableCell>
                        <GroupuiTableCell>{item.role_name}</GroupuiTableCell>
                        <GroupuiTableCell>{item.email_address}</GroupuiTableCell>
                    </>
                ))}

            </GroupuiTable>

            <h3>RocketChat</h3>
            <GroupuiTable columns="3" sticky={false} header={true} widths="80px auto auto" indicator="default">
                <GroupuiTableCell>Status</GroupuiTableCell>
                <GroupuiTableCell>Project Name</GroupuiTableCell>
                <GroupuiTableCell>Role Name</GroupuiTableCell>

                {rocketchat_status.map((item) => (
                    <>
                        {item.success ? (
                            <GroupuiTableCell><GroupuiChip color="success">Sucess</GroupuiChip></GroupuiTableCell>
                        ) : (
                            <GroupuiTableCell><GroupuiTooltip><GroupuiChip color="error">Error</GroupuiChip><div style={{ whiteSpace: "pre-wrap" }} slot="tooltip">{item.error_message}</div></GroupuiTooltip></GroupuiTableCell>

                        )}
                        <GroupuiTableCell>{item.recipient_name}</GroupuiTableCell>
                        <GroupuiTableCell>{item.role_name}</GroupuiTableCell>
                    </>
                ))}
            </GroupuiTable>
            {/* <div className="button-col">
                <GroupuiButton variant="secondary" onClick={() => setStep(1)}>Edit again</GroupuiButton>
                <GroupuiButton onClick={() => setStep(3)}>Send</GroupuiButton>
            </div> */}
        </GroupuiGrid>
    </div >
};