import { GroupuiGrid, GroupuiGridCol, GroupuiHeadline, GroupuiInput, GroupuiTextarea, GroupuiSearchField, GroupuiSearchSuggestion, GroupuiButton, GroupuiCheckbox, GroupuiButtonGroup, GroupuiButtonGroupButton } from '@group-ui/group-ui-react';
import { multiStepContext } from '../StepContext';
import { useContext, useState } from 'react';

export default function ComposeNewMessage({ Templates, Recipients, RecipientsChecked, setRecipientsChecked, Severity, setSeverity, Subject, setSubject, Message, setMessage, SuggestionSelected }) {
    const { setStep } = useContext(multiStepContext)
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCheckboxes] = useState([]);
    const [isSubjectMissing, setIsSubjectMissing] = useState('');
    const [isMessageMissing, setIsMessageMissing] = useState('');
    const [isSeverityMissing, setIsSeverityMissing] = useState('');
    const [isRecipientsMissing, setIsRecipientsMissing] = useState('');

    const handleSeverityChange = event => {
        setSeverity(event.target.id)
    }
    const handleSubjectChange = event => {
        setSubject(event.target.value);
    }
    const handleTextAreaChange = event => {
        setMessage(event.target.value);
    };
    const handlePreviewButtonClick = () => {
        if (Severity === undefined || Severity === "" || Subject === undefined || Subject === "" || Message === undefined || Message === "" || Object.keys(RecipientsChecked).length === 0) {
        } else {
            setStep(2)
        }
        if (Severity === undefined || Severity === "") {
            setIsSeverityMissing(true)
        } else {
            setIsSeverityMissing(false)
        }

        if (Subject === undefined || Subject === "") {

            setIsSubjectMissing(true)
        } else {
            setIsSubjectMissing(false)
        }

        if (Message === undefined || Message === "") {
            setIsMessageMissing(true)

        } else {
            setIsMessageMissing(false)
        }

        if (Object.keys(RecipientsChecked).length === 0) {
            setIsRecipientsMissing(true)

        } else {
            setIsRecipientsMissing(false)
        }


    }
    const handleMainCheckboxChange = (recipientName) => {
        const newCheckboxState = { ...RecipientsChecked };
        newCheckboxState[recipientName] = !newCheckboxState[recipientName];

        // If the parent checkbox is checked, check all children; otherwise, uncheck all children
        if (newCheckboxState[recipientName]) {
            newCheckboxState[recipientName] = {};
            Recipients.forEach(item => {
                if (item.recipient_name.S === recipientName) {
                    const roleName = item.role_name.S;
                    newCheckboxState[recipientName][roleName] = true;
                }
            });
        } else {
            delete newCheckboxState[recipientName];
        }

        setRecipientsChecked(newCheckboxState);
    };

    const handleSubCheckboxChange = (recipientName, roleName) => {
        setRecipientsChecked(prevState => {
            const parentState = prevState[recipientName] || {};

            const newParentState = {
                ...parentState,
                [roleName]: !parentState[roleName]
            };

            // If all child checkboxes are unchecked, uncheck the parent checkbox
            if (Object.values(newParentState).every(val => val === false)) {
                const newCheckboxState = { ...prevState };
                delete newCheckboxState[recipientName];
                return newCheckboxState;
            }

            return {
                ...prevState,
                [recipientName]: newParentState
            };
        });
    };
    const renderCheckboxes = () => {
        const checkboxes = [];

        const groupedData = {};
        Recipients.forEach(item => {
            const recipientName = item.recipient_name.S;
            const roleName = item.role_name.S;

            if (!groupedData[recipientName]) {
                groupedData[recipientName] = [];
            }
            groupedData[recipientName].push({ roleName, isChecked: selectedCheckboxes.includes(roleName) });
        });

        for (let recipientName in groupedData) {
            groupedData[recipientName].sort((a, b) => (a.roleName > b.roleName) ? 1 : -1)
            const children = groupedData[recipientName].map(child => (
                <div className='checkbox-sub' key={child.roleName}>
                    <GroupuiCheckbox
                        checked={!!RecipientsChecked[recipientName]?.[child.roleName]}
                        id={recipientName}
                        onGroupuiChange={() => handleSubCheckboxChange(recipientName, child.roleName)}
                    >
                        {child.roleName}
                    </GroupuiCheckbox>
                </div>
            ));

            checkboxes.push(
                <div className="checkbox" key={recipientName}>
                    <GroupuiCheckbox
                        checked={!!RecipientsChecked[recipientName]}
                        onGroupuiChange={() => handleMainCheckboxChange(recipientName)}
                        id={recipientName}
                    >
                        <b>{recipientName}</b>
                    </GroupuiCheckbox>
                    {children}
                </div>
            );
        }

        return checkboxes;
    };

    //fill templates when searchField is empty
    const fillTemplates = (event) => {
        if (event.target.value === null) {
            setSearchResults(Templates)
        }
    }
    const searchTemplateName = (event) => {
        const newSearchTerm = event.target.value;
        const matchingTemplates = [];
        for (const item of Templates) {
            if (item.template_name.S.toLowerCase().includes(newSearchTerm.toLowerCase()) || newSearchTerm === "") {
                matchingTemplates.push(item);
            }
        }
        setSearchResults(matchingTemplates)
    };


    return <div>
        <GroupuiGrid gutter="16px" margin-type="custom" margin="0px">
            <GroupuiHeadline heading="h1">
                Send a message to inform application teams
            </GroupuiHeadline>
            {/* <GroupuiText id="description">
              The first inspection after delivery is due for most VW vehicles
              after three years or 60,000 kilometers, then every 2 years or
              60,000 km.
            </GroupuiText> */}
            <GroupuiHeadline heading="h5" className="sub-headings">
                Message information
            </GroupuiHeadline>
            <GroupuiGridCol m={2} l={6}>
                <GroupuiSearchField placeholder="Search for a template" onGroupuiInputFocused={fillTemplates} onGroupuiDidLoad={fillTemplates} autofocus={true} directlyOpen={true} onGroupuiSuggestionSelected={SuggestionSelected} onGroupuiChange={searchTemplateName}>
                    <span slot="label">Template</span>
                    <span slot="description">Select a template</span>
                    {searchResults.map((item, index) => (
                        <GroupuiSearchSuggestion key={index}>
                            {item.template_name.S}
                        </GroupuiSearchSuggestion>
                    ))}
                </GroupuiSearchField>
            </GroupuiGridCol>
            <GroupuiHeadline heading="h5" className="sub-headings">
                Severity
            </GroupuiHeadline>
            <div className="buttongroup-radiobutton container-border flex-center">
                <GroupuiButtonGroup className="buttongroup-radiobutton" variant="radio" onClick={handleSeverityChange}>
                    <GroupuiButtonGroupButton className="buttongroup-radiobutton" id="low" checked={Severity === 'low' && true}>Low</GroupuiButtonGroupButton>
                    <GroupuiButtonGroupButton id="high" onGroupuiChange={handleSeverityChange} checked={Severity === 'high' && true}>High</GroupuiButtonGroupButton>
                    <GroupuiButtonGroupButton id="critical" onGroupuiChange={handleSeverityChange} checked={Severity === 'critical' && true}>Critical</GroupuiButtonGroupButton>
                </GroupuiButtonGroup>
                <div className="warning-text">
                    {isSeverityMissing && <span slot="description">Please select a severity</span>}
                </div>
            </div>
            <GroupuiHeadline heading="h5" className="sub-headings">
                Subject
            </GroupuiHeadline>
            <GroupuiInput severity={isSubjectMissing ? 'danger' : undefined} value={Subject} onGroupuiChange={handleSubjectChange} placeholder="Your subject">
                {isSubjectMissing && <span slot="description">Please enter a subject</span>}
            </GroupuiInput>
            <GroupuiHeadline heading="h5" className="sub-headings">
                Message
            </GroupuiHeadline>
            <GroupuiTextarea
                severity={isMessageMissing ? 'danger' : undefined}
                id="TemplateTextArea"
                placeholder="Your message"
                rows={14}
                className="textarea"
                value={Message}
                onGroupuiChange={handleTextAreaChange}
                autoExpand={false}
            >
                {isMessageMissing && <span slot="description">Please enter a message</span>}
            </GroupuiTextarea>
            <GroupuiHeadline heading="h5" className="sub-headings">
                Recipients
                <div className="warning-text">
                    {isRecipientsMissing && <span slot="description">Please select recipients</span>}
                </div>
            </GroupuiHeadline>


            {renderCheckboxes()}
            <div className="button-col">
                <GroupuiButton variant="secondary">Cancel</GroupuiButton>
                <GroupuiButton onClick={handlePreviewButtonClick}>Review</GroupuiButton>

            </div>
        </GroupuiGrid >

    </div >
};